import React from "react"
import styled from "styled-components"
import Button from "./Button"

const Heading = styled.h3`
  margin-top: 0;
`

const Panel = styled.div`
  width: 300px;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 24px;
  background-color: white;
  padding: 28px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04) !important;
  border-radius: 6px !important;
  border: 1px solid #ececec !important;
  z-index: 999;
`

const Copy = styled.p`
  line-height: 1.8;
  margin-bottom: 24px;
`

const CookieBar = ({ onClick }) => (
  <Panel>
    <Heading>Cookies</Heading>
    <Copy>
      This site uses cookies. Some of them are essential while other help us
      improve your experience.{" "}
      <a
        href="https://additiv.com/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </a>
    </Copy>
    <Button onClick={onClick}>Okay</Button>
  </Panel>
)

export default CookieBar
