import React from "react"

const Logo = props => (
  <svg width={132} height={42} {...props}>
    <path
      fill="#D7E3F4"
      d="M34.064 37.203a3.661 3.661 0 01-5.16 0l-13.54-13.539a3.66 3.66 0 010-5.16l13.54-13.539a3.661 3.661 0 015.16 0l13.539 13.539a3.661 3.661 0 010 5.16L34.064 37.203z"
    />
    <path
      fill="#4261FF"
      d="M24.942 37.203a3.661 3.661 0 01-5.16 0L6.244 23.664a3.658 3.658 0 010-5.16L19.783 4.965a3.661 3.661 0 015.16 0l13.54 13.539a3.66 3.66 0 010 5.16L24.942 37.203z"
    />
    <path
      fill="#1D2A2E"
      d="M56.032 34.93V8.159h9.221c2.082 0 3.671.127 4.767.383 1.535.354 2.846.993 3.93 1.918 1.41 1.193 2.47 2.718 3.171 4.574.698 1.857 1.049 3.978 1.049 6.363 0 2.035-.239 3.835-.713 5.406-.478 1.572-1.082 2.869-1.826 3.898-.743 1.029-1.556 1.84-2.438 2.43s-1.946 1.037-3.194 1.342-2.683.455-4.301.455l-9.666.002zm3.544-3.16h5.715c1.766 0 3.15-.164 4.154-.494 1.007-.325 1.807-.791 2.402-1.387.84-.84 1.492-1.969 1.963-3.389.467-1.416.705-3.134.705-5.156 0-2.8-.461-4.952-1.383-6.456-.92-1.503-2.033-2.51-3.352-3.022-.95-.365-2.479-.548-4.582-.548h-5.625l.003 20.452zm23.649 3.16V8.159h18.063v3.159h-14.52v8.291H99.33v3.159H86.768v12.161l-3.543.001zm21.457-8.602l3.34-.291c.16 1.338.526 2.436 1.106 3.295.576.859 1.476 1.553 2.69 2.084 1.22.527 2.587.793 4.107.793 1.354 0 2.547-.201 3.58-.604 1.033-.4 1.807-.951 2.311-1.652.507-.701.761-1.465.761-2.293 0-.84-.246-1.574-.732-2.201-.485-.627-1.29-1.152-2.409-1.578-.722-.281-2.308-.714-4.769-1.304s-4.183-1.148-5.168-1.673c-1.276-.669-2.231-1.5-2.858-2.49-.625-.993-.938-2.104-.938-3.333 0-1.351.383-2.614 1.148-3.789.77-1.174 1.889-2.067 3.36-2.675 1.476-.609 3.111-.914 4.914-.914 1.981 0 3.733.319 5.25.958s2.681 1.579 3.496 2.821c.815 1.243 1.254 2.648 1.313 4.219l-3.396.255c-.186-1.692-.805-2.969-1.854-3.835-1.057-.863-2.606-1.296-4.666-1.296-2.146 0-3.705.393-4.686 1.178-.979.786-1.471 1.732-1.471 2.84 0 .962.348 1.753 1.043 2.374.68.622 2.461 1.258 5.338 1.909 2.879.651 4.854 1.22 5.926 1.708 1.561.718 2.711 1.628 3.453 2.729.742 1.104 1.111 2.371 1.111 3.812 0 1.424-.404 2.767-1.223 4.022-.816 1.263-1.988 2.24-3.517 2.941-1.526.699-3.248 1.051-5.158 1.051-2.424 0-4.452-.354-6.09-1.061-1.64-.705-2.922-1.768-3.854-3.188-.919-1.419-1.411-3.023-1.458-4.812z"
    />
  </svg>
)

export default Logo
