import React from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"
import Button from "./Button"
import BaseLogo from "./Logo"
import CookieBar from "./CookieBar"
import "../style.css"

const Header = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  height: 64px;
`

const Nav = styled.nav`
  background-color: white;
  border-bottom: 1px solid #ececec;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
`

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  height: 100%;
  align-items: center;
  overflow-x: auto;
`

const NavListItem = styled.li`
  & + & {
    margin-left: 1.5rem;
  }
`

const NavLink = styled(Link)`
  color: #25282e;
  text-decoration: none;
  font-size: 14px;
  line-height: 24px;
  opacity: 0.5;
  font-weight: 500;
`

const Logo = styled(BaseLogo)`
  display: block;
  margin-left: -8px;
`

NavLink.defaultProps = {
  activeClassName: "active",
}

/**
 * Get Cookie Bar's cookie if available
 * @return {string} cookie value
 */
function getCookie() {
  const cookieValue = document.cookie.match(/(;)?cookiebar=([^;]*);?/)

  if (cookieValue == null) {
    return undefined
  } else {
    return decodeURI(cookieValue[2])
  }
}

/**
 * Write cookieBar's cookie when user accepts cookies :)
 * @param {string} name - cookie name
 * @param {string} value - cookie value
 * @return null
 */
function setCookie(name, value) {
  const exdays = 30
  const exdate = new Date()
  exdate.setDate(exdate.getDate() + parseInt(exdays))
  const cValue =
    encodeURI(value) +
    (exdays === null ? "" : "; expires=" + exdate.toUTCString() + ";path=/")
  document.cookie = name + "=" + cValue
}

const Layout = ({ loading, children }) => {
  const [isCookieBarShown, setIsCookieBarShown] = React.useState(false)
  const {
    allSwaggerApi: { edges },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      allSwaggerApi(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            name
            title
          }
        }
      }
    }
  `)

  const header = (
    <Header className="header">
      <Nav>
        <NavLink as="a" href={`/`} style={{ opacity: 1 }}>
          <Logo />
        </NavLink>
        <NavList>
          {edges.map(({ node }) => {
            return (
              <NavListItem key={node.name}>
                {/* Regular links are used here as the client side routing blocks the rendering of the Redoc pages, giving impression that the application is not responsive */}
                <NavLink as="a" href={`/${node.name}`}>
                  {node.title}
                </NavLink>
              </NavListItem>
            )
          })}
          <NavListItem>
            <Button as="a" href="https://www.additiv.com/contact-general/">
              Contact Us
            </Button>
          </NavListItem>
        </NavList>
      </Nav>
    </Header>
  )

  const handleCookieBarClick = () => {
    setCookie("cookiebar", "false")
    setIsCookieBarShown(false)
  }

  React.useEffect(() => {
    if (getCookie() !== "false") {
      setIsCookieBarShown(true)
    }
  }, [])

  return (
    <div className={loading ? "loading-js" : ""}>
      {header}
      {children}
      {isCookieBarShown && <CookieBar onClick={handleCookieBarClick} />}
    </div>
  )
}

export default Layout
