import styled from "styled-components"

const Button = styled.button`
  display: inline-block;
  border: none;
  border-radius: 999px;
  color: #fff;
  font-weight: 600;
  background-color: rgb(54, 87, 255);
  text-decoration: none;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 6px 24px;
  font-size: 13px;
  line-height: 24px;
  transition-duration: 0.1s;
  white-space: nowrap;
  &:hover {
    box-shadow: 0px 0px 5px 0px rgba(66, 98, 255, 1);
  }
`

export default Button
